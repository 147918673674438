@media only screen and (max-width : 768px) {}

@media only screen and (max-width : 480px) {
    .docs > div > p {
        font-size: 18px !important;
    }
    .docsTextBlock > p {
        font-size: 16px !important;
    }
}

.docsBlock {
    gap: 60px;
}
.docs {
    gap: 20px;
}
.docs > div {
    background: #FFFFFF;
    box-shadow: var(--shadow);
    border-radius: 5px;
    padding: 20px 30px;
}
.docs > div > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0px;
}

.docsTextBlock {
    gap: 15px;
}
.docsTextBlock > div {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: var(--button-bg);
    text-transform: uppercase;
}
.docsTextBlock > p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #898989;
    margin-bottom: 0px;
}