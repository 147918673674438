@media only screen and (max-width : 768px) {
    .linksHeader {
        display: none !important;
    }
    .navToggler {
        display: block !important;
    }
}

@media only screen and (max-width : 480px) {
    .linksHeader {
        display: none !important;
    }
    .navToggler {
        display: block !important;
    }
}

.headBlock {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    backdrop-filter: blur(58px);
    background: rgba(255, 255, 255, .78);
}

.navLinks {
    gap: 40px;
}

.navToggler {
    display: none;
}

.linksOffcanvas {
    gap: 15px;
}
.linksOffcanvas > .link {
    font-size: 14px;
}