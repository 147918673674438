@media only screen and (max-width : 768px) {}

@media only screen and (max-width : 480px) {
  .textInf {
    font-size: 18px !important;
  }

  .accordion-button {
    font-size: 16px !important;
  }
}

:root {
  --bg: #fff;
  --links-header-text-color: #787878;
  --links-header-hover-text-color: #4fb5eb;
  --button-bg: #4FB5EB;
  --button-border-radius: 15px;
}

body {
  background-color: var(--bg) !important;
  font-family: 'Montserrat' !important;
}

svg {
  font-family: none;
}

.link {
  font-weight: 400;
  font-size: 16px;
  color: var(--links-header-text-color);
  text-decoration: none !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.link:hover {
  color: var(--links-header-hover-text-color) !important;
}

.active {
  color: var(--links-header-hover-text-color) !important;
}

.accordion-button {
  border-radius: 5px !important;
  padding: 20px 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #000000 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
}

.accordion-body {
  padding: 20px 30px !important;
  background-color: #fbfbfb !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  background-color: #fff !important;
}