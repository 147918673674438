@media only screen and (max-width : 768px) {
    .accordion-header>.accordion-button {
        font-size: 16px !important;
    }
    .textInf {
        font-size: 20px !important;
    }
}

@media only screen and (max-width : 480px) {
    .textInf {
        font-size: 18px !important;
    }
    .accordion-header > .accordion-button {
        font-size: 16px !important;
    }
}

.infBlock {
    gap: 50px;
}
.textInf {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 0px;
}
.accordionBlock {
    gap: 20px;
    border: none !important;
    border-radius: 0px !important;
}
.accordionItem {
    background: #FFFFFF !important;
    box-shadow: var(--shadow) !important;
    border-radius: 5px !important;
    border: none !important;
}
/*
.accordion-button {
    border-radius: 5px !important;
    padding: 20px 30px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px;
    color: #000000 !important;
}
.accordion-button:focus {
    box-shadow: none !important;
    border: none !important;
}
.accordion-body {
    padding: 20px 30px !important;
    background-color: #fbfbfb !important;
}
.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    background-color: #fff !important;
}*/