@media only screen and (max-width : 768px) {
    .topBlock>div:first-child>div:first-child>div:nth-child(2) {
            font-size: 14px !important;
    }
    .buttonTopBlock {
        font-size: 18px !important;
    }
    .topBlock>div:first-child>div:first-child>div:first-child {
        font-size: 26px;
        line-height: inherit;
    }
    .textBlock {
        font-size: 18px !important;
    }
    .imgMain {
        opacity: .35;
    }
}
@media only screen and (max-width : 480px) {
    .imgMain {
        opacity: .25;
    }
    .topBlock > div:first-child > div:first-child > div:nth-child(2) {
        font-size: 16px !important;
    }
    .topBlock > div:first-child > div:first-child > div:first-child {
        font-size: 28px;
        line-height: inherit;
    }
    .cardsPrice {
        flex-direction: column !important;
    }
    .buttonTopBlock {
        font-size: 18px !important;
    }
    .textBlock {
        font-size: 18px !important;
    }
}


.imgMain {
    position: absolute;
    right: 0;
    top: 94px;
    /* bottom: 0; */
    z-index: -1;
}

.imgMain > img {
    width: 626px;
    height: 606px;
}

.topBlock {
    /* width: 546px; */
    height: calc(100vh - 80px);
}
.topBlock > div:first-child {
    gap: 65px;
}
.topBlock > div:first-child > div:first-child > div:first-child {
    margin-bottom: 5px;
}
.topBlock > div:first-child > div:first-child > div:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #353638;
}
.topBlockHeading {
    font-weight: 700;
    font-size: 44px;
    line-height: 54px;
    color: #4FB5EB;
}

.buttonTopBlock {
    padding: 15px 45px;
    background: var(--button-bg);
    font-weight: 400;
    font-size: 24px;
    color: #fff;
    border-radius: var(--button-border-radius);
    gap: 15px;
    width: fit-content !important;
    align-items: center;
}
.buttonTopBlock:hover {
    cursor: pointer;
}

.blockMainPage {
    padding: 80px 0px;
}

.mBlock {
    gap: 60px;
}
.textBlock {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 24px;
    text-align: center;

    color: #353638;
}

.cardsPrice {
    gap: 24px;
    flex-direction: row;
}
.cardsPrice > div {
    padding: 15px 30px;
    gap: 15px;
    background: #FFFFFF;
    box-shadow: var(--shadow);
    border-radius: 4px;
} 
.serviceName {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.servicePrice {
    font-weight: 700;
    font-size: 16px;
    color: var(--button-bg);
    margin-bottom: 0px !important;
}
.flexBlock {
    gap: 40px;
}
.textFlexBlock {
    font-weight: 300;
    font-size: 14px;
    color: #787878;
}
.highlighted {
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    color: #4FB5EB;
}
.formMainBlock {
    gap: 60px;
}
.formBlock {
    gap: 24px;
}
.inputMain {
    padding: 16px 25px;
    background: #E6E6E6;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    color: #787878;
}
.inputMain::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #787878;
}
.textareaMain {
    padding: 16px 25px;
    background: #E6E6E6;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    color: #787878;
    overflow: auto;
    height: 100px;
}
.textareaMain::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: #787878;
}
.formMainButs {
    gap: 26px;
}
.formLText {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    padding: 0px 20px;
    color: #939393;
}
.formButton {
    padding: 15px 45px;
    background: #4FB5EB;
    border-radius: 15px;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    width: fit-content;
}