@media only screen and (max-width : 768px) {
    .infTab>p {
        font-size: 14px !important;
    }
    .nameText {
        font-size: 12px !important;
    }
}
@media only screen and (max-width : 480px) {
    .footerInfBlock {
        flex-direction: column;
        gap: 20px;
        align-items: start !important;
    }
    .infTab {
        flex-direction: row-reverse;
        align-items: start !important;
    }
    .infTab > p {
        text-align: left !important;
    }
    .blockInfo {
        align-items: start !important;
    }
}

.footerBlock {
    background: #FFFFFF;
    box-shadow: var(--shadow);
}
.footerLinksBlock {
    padding: 30px 0px;
    gap: 28px;
}
.logoNameBlock {
    gap: 12px;
}
.nameText {
    font-weight: 400;
    font-size: 16px;
    color: #939393;
    margin-bottom: 0px;
}
.blockInfo {
    gap: 10px;
    align-items: end;
}
.tooltip>div.tooltip-inner {
    background-color: #fafafa !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    color: #939393 !important;
}
.tooltip.show {
    opacity: 1 !important;
}
.tooltip>div.tooltip-arrow::before {
    border-top-color: #fafafa !important;
    color: #fafafa !important;
}
.infTab {
    gap: 10px;
}
.infTab > p {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #939393;
    margin-bottom: 0px;
    line-height: 17px;
}
.footerLinks {
    gap: 26px;
}
.footerLinks > .link {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #939393;
}
.copyrightBlock {
    border-top: 1px solid #e3e3e3;
    padding: 15px 0px;
}
.copyrightBlock > p {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #939393;
    margin-bottom: 0px;
}
.footerInfBlock {
    align-items: center;
}